var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { align: "center" } }, [
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-10" },
        [
          _c("QQButton", {
            attrs: {
              label: "Fatture attesa emissione",
              color: "blue-grey",
              icon: "mdi-alarm",
              size: "xl",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onFattureNonEmesse()
              },
            },
          }),
          _c("QQButton", {
            attrs: {
              label: "Fatture emesse",
              color: "blue-grey",
              icon: "mdi-clipboard-check-multiple-outline",
              size: "xl",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onFattureEmesse()
              },
            },
          }),
          _c("QQButton", {
            attrs: {
              label: "Fatture rifiutate",
              color: "blue-grey",
              icon: "mdi-tow-truck",
              size: "xl",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onFattureRifiutate()
              },
            },
          }),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }